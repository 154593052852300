<template>
  <div :class="$style.root">
    <code
      v-if="isDev && error"
      v-html="error"
    />
    <div v-else>
      {{ $t('error.public.message') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
  const props = defineProps({
    error: {
      type: Object,
      required: true,
    }
  })

  const runtimeConfig = useRuntimeConfig()
  const isDev = runtimeConfig?.public?.env === 'development'
</script>

<style module>
.root {
  padding: var(--unit--default);
}
</style>
